.search-form {
  display: flex;
  justify-content: center;

  &.ant-form-inline {
    .ant-form-item {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .search-auto-complete {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .search-btn {
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
